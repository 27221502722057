<template>
  <div>
    <q-dialog
      ref="modal"
      class="detail-modal"
      :maximized="$q.screen.lt.md"
    >
      <q-card v-if="showing" class="bg-transparent" :style="cardStyle">
        <q-toolbar
          class="text-white bg-primary shadow-2 z-top"
        >
          <q-toolbar-title>
            {{ showing.name }}
            <q-item-label class="text-caption text-white desktop-only q-pb-xs">
              {{ showing.rating }} <q-rating
                v-if="showing.rating"
                v-model="showing.rating"
                :max="5"
                color="white"
                readonly
              />
            </q-item-label>
          </q-toolbar-title>
          <sub class="desktop-only">{{ showing.vicinity }}</sub>
          <q-btn flat @click="$refs.modal.hide()">
            <q-icon name="close" />
          </q-btn>
        </q-toolbar>
        <q-list
          multiline
          no-border
          class="bg-white"
        >
          <q-item-label header>
            {{ $t('photos') }}
          </q-item-label>
          <q-item class="col wrap" style="flex-wrap: wrap!important">
            <img
              v-for="photo in showing.photos"
              :key="photo.photo_reference"
              :src="`${url}/locations/places/photo/${photo.photo_reference}?max_height=75`"
              class="q-pa-xs q-item--clickable q-link cursor-pointer q-focusable q-hoverable"
              @click="showImage(photo.photo_reference)"
            >
          </q-item>
          <q-item-label v-if="showing.reviews" header>
            {{ $t('customer_reviews') }}
          </q-item-label>
          <q-item-label v-else header>
            {{ $t('no_reviews_available') }}
          </q-item-label>
          <q-scroll-area class="full-width" style="height: 600px">
            <q-list>
              <q-item
                v-for="review in showing.reviews"
                :key="review.time"
              >
                <q-item-section side top>
                  <img
                    :src="`${review.profile_photo_url}?key=${googleKey}`"
                    class="avatar"
                  >
                </q-item-section>
                <q-item-section>
                  <q-item-label label>
                    {{ review.author_name }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ review.relative_time_description }}
                  </q-item-label>
                  <q-rating
                    v-if="review.rating"
                    v-model="review.rating"
                    :max="5"
                    color="yellow-8"
                    readonly
                  />
                  <p class="text-body2">
                    {{ review.text }}
                  </p>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-list>
        <!-- </q-scroll-area> -->
      </q-card>
    </q-dialog>
    <m-image-modal v-model="showImageModal" :image="image" />
  </div>
</template>

<script>
import { MImageModal } from 'components/'
export default {
  components: { MImageModal },
  data () {
    return {
      showing: null,
      currentImage: null,
      showImageModal: false
    }
  },
  computed: {
    googleKey () {
      return process.env.VUE_APP_GOOGLE_KEY
    },
    url () {
      return process.env.VUE_APP_API_URL
    },
    image () {
      return {
        url: `${this.url}/locations/places/photo/${this.currentImage}`
      }
    },
    cardStyle () {
      return {
        'max-width': '800px',
        'max-height': `${this.$q.screen.height}px`,
        margin: 0
      }
    }
  },
  methods: {
    showImage (photo) {
      this.currentImage = photo
      this.showImageModal = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.detail-modal
  .q-toolbar-title
    padding-top: 8px
    padding-bottom: 8px
  .layout-padding
    padding: 24px
  .q-list
    flex-basis: auto
    max-width: none
    .q-item, .q-item-label header
      img.avatar
        width: 38px
        height: 38px
.loader
  width: 100px
  height: 100px
  margin: auto
</style>
